<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- TABLE START -->
      <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
          <template slot="thead">
            <vs-th sort-key="name">Names</vs-th>
            <vs-th sort-key="slug">Slug</vs-th>
            <vs-th sort-key="created_at"></vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.name">{{tr.name}}</vs-td>
              <vs-td label="Slug" :data="tr.slug">{{tr.slug}}</vs-td>
              <vs-td :data="tr.id" width="10">
                <div class="flex">
                  <vs-button size="small" type="line" :to="{name:'company_numberings', params:{company_id: id, transaction_type_id: tr.id}}" icon-pack="feather" icon="icon-edit"></vs-button>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
      <!-- TABLE END -->
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  props:{
    id: {
      type: String,
      default: null
    }
  },
  data(){
    return {
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null
    }
  },
  watch:{
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.transaction_types.rows
    })
  },
  methods:{
    ...mapActions({
      dispatchIndex: 'transaction_types/index'
    }),
    async getData(){
      let payload = {
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal
      }
      await this.dispatchIndex(payload)
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    }
  },
  async mounted(){
    await this.getData()
  }
}
</script>

<style>

</style>