<template>
  <div class="vx-row">
    <div class="vx-col sm:w-full md:w-1/2 mb-base">
      <vx-card :title="$route.params.id ? `Edit Company` : `Add New Company`">
        <form-create :id="$route.params.id" @on-success="$router.push({name: 'companies'})">
          <template v-slot:footer>
            <vs-button :to="{name:'companies'}" type="border" color="warning">Back</vs-button>
          </template>
        </form-create>
      </vx-card>
    </div>
    <div v-if="$route.params.id" class="vx-col sm:w-full md:w-1/2 mb-base">
      <vx-card :title="`Numbering Setting`">
        <list-transaction-type :id="$route.params.id" />
      </vx-card>
    </div>
  </div>
</template>

<script>
import FormCreate from './sections/Form.vue'
import ListTransactionType from './sections/ListTransactionType.vue'
export default {
  components:{
    FormCreate,
    ListTransactionType
  }
}
</script>

<style>

</style>