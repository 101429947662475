<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- FORM START -->
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="name" label="Company Name" v-model="name"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('name')">{{errors.first('name')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <small class="ml-2">Area</small>
          <v-select v-model="area_id" :options="areas" name="area_id" label="name" :reduce="e => e.id"></v-select>
          <span class="text-danger text-sm" v-show="errors.has('area_id')">{{errors.first('area_id')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="address" label="Address" v-model="address"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('address')">{{errors.first('address')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-1/2">
          <vs-input class="w-full" label="Phone Number" v-model="phone"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-1/2">
          <vs-input class="w-full" label="Email Address" v-model="email"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-1/2">
          <vs-input class="w-full" label="Website Address" v-model="website"></vs-input>
        </div>
      </div>
      <vs-divider></vs-divider>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="Contact Person Name" v-model="contact_person"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="Contact Person Phone" v-model="contact_person_no"></vs-input>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <div class="vx-col w-full flex mb-3">
            <vs-checkbox v-model="is_center">Active</vs-checkbox>
          </div>
        </div>
      </div>
      <!-- FORM END -->
    </div>
    <div class="vx-col w-full flex">
      <vs-button @click="store" color="primary" icon-pack="feather" icon="icon-save">Save</vs-button>&nbsp;
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import vSelect from 'vue-select'

export default {
  props:{
    id: {
      type: String,
      default: null
    }
  },
  components:{
    vSelect
  },
  data(){
    return {
      name: "",
      area_id: "",
      address: "",
      phone: "",
      email: "",
      website: "",
      contact_person: "",
      contact_person_no: "",
      is_center: true,
    }
  },
  computed:{
    ...mapState({
      areas: state => state.areas.all
    })
  },
  methods:{
    ...mapActions({
      dispatchStore: 'companies/store',
      dispatchUpdate: 'companies/update',
      dispatchShow: 'companies/show',
      dispatchAllArea: 'areas/all',
    }),
    store(){
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        let payload = {
          id: this.id,
          name: this.name,
          area_id: this.area_id,
          address: this.address,
          phone: this.phone,
          email: this.email,
          website: this.website,
          contact_person: this.contact_person,
          contact_person_no: this.contact_person_no,
          is_center: this.is_center,
        }
        try {
          if (this.id) {
            let {data} = await this.dispatchUpdate(payload)
            this.$emit('on-success', data)
          } else {
            let {data} = await this.dispatchStore(payload)
            this.$emit('on-success', data)
          }
        } catch (error) {
          this.$vs.notify({
            title: 'Oops!',
            text: error.data.message,
            color: 'danger'
          })
        }
      })
    },
    async getDetail(){
      let { data } = await this.dispatchShow(this.id)
      this.name = data.name
      this.area_id = data.area_id
      this.address = data.address
      this.phone = data.phone
      this.email = data.email
      this.website = data.website
      this.contact_person = data.contact_person
      this.contact_person_no = data.contact_person_no
      this.is_center = data.is_center
    }
  },
  created(){
    if (this.id) {
      this.getDetail()
    }
    this.dispatchAllArea();
  }
}
</script>

<style>

</style>